const ImageCard = ({image, title}) => {
    return (
        <div className="bg-white w-80 relative flex flex-col text-md image-container group">
            <img alt={''} src={image} className='shadow-md object-cover min-h-full'></img>
            <div className='absolute top-0 left-0 bottom-0 right-0 opacity-0 w-full h-full bg-sky-600 shadow-md transition duration-300 ease-in group-hover:opacity-100'>
                <div className="text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                    <div className='text-white'>{title}</div>
                    <button type="button" className="rounded-md mt-3 bg-sky-800 px-3 py-2 text-xs font-semibold text-white hover:bg-sky-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600">
                        Read more
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ImageCard;