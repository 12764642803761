import { UserCircleIcon } from '@heroicons/react/24/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';

const Card = ({testimonial, user}) => {
    return (
        <div className="bg-gray-100 p-6 max-w-xl relative flex flex-col rounded-md shadow-md text-md">
            <div className='h-28 w-28 bg-white -top-14 mx-16 lg:mx-52 absolute rounded-full'>
                <UserCircleIcon className="h-28 w-28 text-gray-300 absolute" />
            </div>
            <div className='text-7xl'>
                <FontAwesomeIcon icon={faQuoteLeft} className='text-gray-400'/>
            </div>
            <div className='pt-10 flex-grow text-gray-500'>{testimonial}</div>
            <div className='pt-10 text-center font-bold text-sky-500'>- {user}</div>
        </div>
    )
}

export default Card;