import React from "react";
import Home from './components/Home/Home';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Routes, Route } from 'react-router-dom';
import Main from "./components/Main/Main";
import Contact from "./components/Pages/Contact";
import Testimonials from "./components/Pages/Testimonials";
import ProfessionalFootprints from "./components/Pages/ProfessionalFootprints";

const App = () => {
  return (
    <>
       <Routes>
          <Route path="/" element={<Home />}>
            <Route index element={<Main />} />
            <Route path="testimonials" element={<Testimonials />} />
            <Route path="contact" element={<Contact />} />
            <Route path="footprints" element={<ProfessionalFootprints />}/>
            <Route path="*" element={<p>Not found!</p>} />
          </Route>
      </Routes>
    </>
  );
}

export default App;
