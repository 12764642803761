import ImageCard from "../Shared/ImageCard";
import { footprints } from "../Shared/data/footprints";

const ProfessionalFootprints = () => {
    return (
        <div className="max-w-7xl mx-auto p-12">
             <h2 className="border-b border-gray-900/10 text-2xl font-semibold leading-7 text-amber-500 pb-4">
                 Professional Footprints
            </h2>
            <div className="flex flex-row flex-wrap justify-center lg:justify-start gap-x-12 gap-y-10 mt-10">
                {
                    footprints.map(data => 
                        <ImageCard title={data.title} image={data.pictures}/>
                    )
                }
            </div>
        </div>
    )
}

export default ProfessionalFootprints;