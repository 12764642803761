export const testimonials = {
    'Corporate': [
        {
            user: 'Ravindran Dayama',
            testimonial: `Training is excellent with lot of examples. 
            It shows the path to the individual for effortless and memorable journey in professional and personal life. 
            It gives us the power to control our trigger points and remain calm at the same time continue our thought process`
        },
        {
            user: 'Manju Nagendra',
            testimonial: `Excellent English communication, excellent presentation, excellent experience, excellent relationship with training members. 
            Very patiently explained all our queries in detail. Solutions to all our problems. Plenty of examples and interactions. 
            Brought a change in my profession & personal life (maturity). `
        },
        {
            user: 'Venkatesh Patil',
            testimonial: `This program was really eye-opener for me as I generally react first before thinking. 
            But after the training program I realized thinking process will really give us positive results. 
            It also helped me to change my behavioural mind-set towards my family as well as at my workplace.`
        },
        {
            user: 'A. K. Nigam',
            testimonial: 'It was wonderful session, especially how to understand body language.'
        },
        {
            user: 'Kapil Saini',
            testimonial: 'I want to say only one word regarding this training and for you is AWESOME'
        },
        {
            user: 'Anand R. Gokhale',
            testimonial: 'Training was excellent and very informative. It will help me in form of Self-Management and Time Management.'
        }
    ],
    'Students': [
        {
            user: 'Pancham Rupani (HRM)',
            testimonial: `Content was crisp and adequate enough for us to accept the challenges in the organization. 
            We had got practical experience of the corporate world. With help of class presentations we could develop our own communication skills, overall personality development, learning topics which helped in the overall development even though they were not a part of our syllabus. 
            Visiting the corporate companies giving us live experience and briefing the practice in organization. It was a good overall experience. 
            Ma'am was open to our ideas and always had a positive approach to our ideas. Different way of learning, got a good individual evaluation with particular individual observation.`            
        },
        {
            user: 'Nikita Rohra (OB)',
            testimonial: `To the Professor who taught more than a Subject!! Words cannot express how thankful I am that I had you as a teacher. 
            You not only helped me through a subject but also taught me lessons that I will need to know to get through life successfully. 
            You also helped me realize that the world is bigger than what I experience every day. 
            It was a pleasure meeting you and interacting with you. 
            I consider myself blessed to have been given the opportunity to attend your lectures over the last 4 months I may not have been your brightest student, 
            but I will never forget how I continue to grow from your lessons. I wouldn't have asked for a better mentor. Thank you for all the advice `            
        },
        {
            user: 'Nishita Seth (HRM)',
            testimonial: `Clarification of all the HR related aspects. All day to day organizational events that did not fall in the syllabus 
            were also covered that would help us in the organisation in the future. 
            Teaching through our presentations was a very helpful method for us which made us understand the subject better and 
            your valuable inputs were of great significance. Industry visits helped us understand how the things actually function. 
            Very valuable feedback for our own benefits and growth. A core mentor! `
        },
        {
            user: 'Training & Development Student (2013-15 batch)',
            testimonial: `Content was very relevant and practical. It was wholistic and not only about training and development. 
            We could get information on industry practices, ways for better employability and much more. The best part of the whole process is we are empowered to take up things. 
            Madam acted as a wonderful facilitator so that we can transform from a caterpillar to a butterfly. T
            he presentation & workshop were not an easy task and that is where major learning came from. 
            The relationship was very warm and cordial. Ma'am could be easily approached and would always come up with interesting suggestions. 
            She would always encourage us and empower us. Our creative juices would flow that way. 
            Ma'am can seem slightly intimidating to some but if one is genuine and sincere she is the best guide. `
        },
        {
            user: 'Harshad S. Ugale (HRM MMS: 2013-15 batch)',
            testimonial: `Strong knowledge base as it comes with a vast experience in the HR industry. This helps a lot while evaluating the topics covered in the subject. 
            Teaching with practical examples helps to understand the concepts thoroughly. The process of teaching is quite different from typical theory teaching. 
            Making the concept understand through presentations and videos and then relating it to theories makes it easy to understand. 
            The etiquette and grooming discipline insisted by her helps to maintain a corporate requirement, which will help us in near future. 
            Disciplined relation with students - sometimes acts as a friend to them while at times acts as a strict guide, when necessary. 
            This makes students comfortable to deal with sincerity and discipline.`
        },
        {
            user: 'Dipal Shah (MMS: 2013-15 batch)',
            testimonial: `Content: Exhaustive. Application - based. Covered more than just the syllabus mentioned. 
            Industry practices were given importance. Process:Presentations and videos were helpful, covered most of the topics. 
            Industry related examples were also given. Short presentations were helpful as they covered topics apart from the syllabus 
            Relationship:.Very cordial relationship.Mentored us well in respect to teaching as well as disciplinary terms. 
            Guided well in respect to not just academics but also living a better and meaningful life. `
        },
        {
            user: 'MMS – Dec 2016 – Organizational Behavior',
            testimonial: `Excellent in-depth subject knowledge
            One of the best professors in this business school
            Innovative teaching methods ( learning OB from movies was fun)
            Human Centered Design Projects opened new landscapes encouraging entrepreneurship and new thought processes – creating jobs rather than just looking for jobs
            Covered extra relevant modules apart from the prescribed syllabus
            A professor who is has a high IQ as well as EQ
            A subject knowledge expert who is also a nurturing mentor
            Rapport and relationship with students is of respect
            A professor who is meticulous in planning and excellent in delivery`
        }
    ]
    
}