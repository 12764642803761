import React from 'react';

import CarouselSlider from './CarouselSlider';

const Main = () => {
    return (
        <>
            <div className='bg-gradient-to-r from-violet-500 to-sky-400 w-full'>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="mx-auto max-w-4xl py-4 lg:py-16">
                        <div className="my-6 flex flex-col lg:flex-row justify-around items-center">
                            <div className='border-2 rounded-full bg-inherit w-70 h-70 p-2'>
                                <img
                                    className='h-40 w-40 lg:h-60 lg:w-60 rounded-full'
                                    src='assets/Farida_Virani_DP1.jpg'
                                    alt='Farida Virani'
                                />
                            </div>
                            <div className='text-white text-center lg:text-left'>
                                <h1 className='text-3xl lg:text-6xl font-semibold py-4'>Farida Virani</h1>
                                <p className='text-md lg:text-xl'>Ph.D - Professor & Corporate Trainer</p>
                                <p className='text-md lg:text-xl'>HR & Behavioural Sciences</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mx-auto max-w-8xl px-4 py-4 sm:px-6 lg:px-8">
                <div className="my-6 mb-4 flex flex-col lg:flex-row w-full justify-between gap-4">
                    <div className='text-gray-800 mr-7 w-full lg:w-4/6'>
                        <p className='text-md'>
                            Through my professional journey of two decades and more, I have worn many hats in my career; Director, Faculty, Corporate Trainer an HR Strategist and a Researcher. 
                            Consequently, I have a distinctive ability to manage and navigate complex challenges with reasonable calm and ease. 
                            My professional expertise includes teaching various subjects related to Applied Behavioural Sciences, Organizational Behavior and Human Resources Management. 
                            <br/><br/><br/>

                            I help organizations gain a competitive advantage by offering HR Consultancy and Behavioral Training Solutions. 
                            The initial organizational diagnostic study assists in identifying skill gaps and helps develop customized content which is delivered through blended learning. Metrics-based training evaluation is offered on demand. 
                            All my consultancy and training solutions are designed in line with the clients' business objectives and strategies.
                            <br/><br/><br/>

                            In the Education Leadership and Management space, I help educational institutions create frameworks, systems and processes with a focus on quality which is vital for Accreditation. 
                            I am also a Programme Facilitator for UNCTAD's (United Nations Conference on Trade and Development) Empretec Programme in India.
                            <br/><br/><br/>

                            A Certified Mediator, I mediate matrimonial disputes and also impart mediation skills to HR Managers, Business Leaders and Lawyers. 
                            <b><em>"Helping you get from where you are, to where you want to be"</em></b>is how I define my role as a Life Coach and a Counselor. 
                            I am a research enthusiast with a number of national / international research papers, newspaper articles and a book to my credit.

                        </p>
                    </div>
                        
                    <div className='w-full lg:w-2/6'>
                        <CarouselSlider />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Main;