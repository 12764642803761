import { ChevronDownIcon } from "@heroicons/react/20/solid";

const Dropdown = (props) => {
    return (
        <div className="relative">
            {
                !props.isMobileMode ? (
                    <div className="inline-flex cursor-pointer px-3 py-2 gap-x-1.5 text-white text-md lg:text-sky-100 hover:text-white"
                        onMouseOver={props.mouseoverHandler}
                        onMouseOut={props.mouseoutHandler}
                        onClick={props.clickHandler}>
                        <div>{props.title}</div>
                        <ChevronDownIcon className="-mr-1 mt-1 h-5 w-5" />
                        {props.children}
                    </div>
                ) : (
                    <>
                        <button type="button" className="inline-flex p-5 w-full gap-x-1.5 text-white text-md"
                            onClick={props.clickHandler}>
                            <div>{props.title}</div>
                            <ChevronDownIcon className="-mr-1 mt-1 h-5 w-5"/>
                        </button>
                        {props.children}
                    </>
                )
            }
        </div>
    )
}

export default Dropdown;