import { useState } from "react";
import Card from "../Shared/Card";
import { testimonials } from '../Shared/data/testimonials';

const testimonialTypes = ['Corporate', 'Students'];

const Testimonials = () => {
    const [activeContentIndex, setActiveContentIndex] = useState(testimonialTypes[0]);
    return (
        <div className="bg-white mx-auto max-w-7xl px-12 py-8  gap-y-16">
            <h2 className="border-b border-gray-900/10 text-2xl font-semibold leading-7 text-amber-500 pb-4 mb-4">
                Testimonials
            </h2>
            <menu className="flex flex-row justify-start">
                {
                    testimonialTypes.map(header => 
                        <button key={header} className={`${activeContentIndex === header ? "opacity-100" : "opacity-50"} 
                            p-3 text-xl font-bold text-indigo-500 rounded-t-md w-full hover:opacity-100 border-b-2 border-b-indigo-500`}
                            onClick={() => setActiveContentIndex(header)}
                        >
                        {header}
                        </button>
                    )
                }
            </menu>
            <div className="mt-20 flex flex-row flex-wrap gap-y-20 gap-x-8">
                {testimonials[activeContentIndex].map((data, index) => (
                    <Card key={index} testimonial={data.testimonial} user={data.user}/>
                ))}
            </div>
        </div>
    )
};

export default Testimonials;