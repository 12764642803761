
import { Link } from 'react-router-dom';

const DropdownItems = ({ submenus, showMenu, isMobileMode, mobileViewClickHandler }) => {
    return (
      showMenu && (
        !isMobileMode ?
        (<ul className="absolute right-2 top-8 text-gray-400 text-md z-10 mt-2 w-56 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {submenus.map((submenu, index) => (
            <li key={index} className="p-2 hover:bg-blue-500 hover:text-white cursor-pointer">
              <Link to={submenu.url}>{submenu.name}
              </Link>
            </li>
          ))}
        </ul>
        ) : (
          <ul className="ml-12 text-white text-md z-10 w-56 rounded-md">
          {submenus.map((submenu, index) => (
            <li key={index} className="p-3 cursor-pointer">
              <Link to={submenu.url} onClick={mobileViewClickHandler}>{submenu.name}
              </Link>
            </li>
          ))}
        </ul>
        )
      )
    );
  };
  
  export default DropdownItems;