import React from 'react';
import Slider from "react-slick";

const settings = {
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    centerMode: true,
    centerPadding: 1
};

const CarouselSlider = () => {

    return (
      <Slider {...settings}>
        <div >
            <img src="assets/1.jpg" alt='' className='mx-auto'/>
        </div>
        <div>
            <img src="assets/2.jpg"  alt='' className='mx-auto'/>
        </div>
        <div>
            <img src="assets/3.jpg"  alt='' className='mx-auto'/>
        </div>
        <div>
            <img src="assets/4.jpg"  alt='' className='mx-auto'/>
        </div>
        <div>
            <img src="assets/5.jpg"  alt='' className='mx-auto'/>
        </div>
        <div>
            <img src="assets/6.jpg"  alt='' className='mx-auto'/>
        </div>
      </Slider>
    );
};

export default CarouselSlider;