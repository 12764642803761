import React, { useState, useRef, useEffect } from 'react';
import DropdownItems from './DropdownItems';
import { Link } from 'react-router-dom';
import Dropdown from '../Shared/Dropdown';
  
const MenuItems = ({item, mobileViewClickHandler}) => {
    const [openDropdown, setOpenDropdown] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);

    let ref = useRef();

    const onMouseEnterMenu = () => {
        window.innerWidth > 960 && setOpenDropdown(true);
    };

    const onMouseLeaveMenu = () => {
        window.innerWidth > 960 && setOpenDropdown(false);
    };

    const closeDropdown = () => {
        openDropdown && setOpenDropdown(false);
    };

    const toggleDropDown = () => {
      setOpenDropdown(prev => !prev);
    }

    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
    }

    useEffect(() => {
        const handler = (event) => {
          if (
            openDropdown &&
            ref.current &&
            !ref.current.contains(event.target)
          ) {
            setOpenDropdown(false);
          }
        };
        document.addEventListener('mousedown', handler);
        document.addEventListener('touchstart', handler);
        return () => {
          // Cleanup the event listener
          document.removeEventListener('mousedown', handler);
          document.removeEventListener('touchstart', handler);
        };
    }, [openDropdown]); 

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    
    const isMobile = width <= 768;

    return (
        <>
            {item.submenu ? 
                (
                  <Dropdown title={item.name} isMobileMode={isMobile}
                    mouseoverHandler={onMouseEnterMenu} 
                    mouseoutHandler={onMouseLeaveMenu}
                    clickHandler={isMobile ? toggleDropDown : closeDropdown}>
                    <DropdownItems submenus={item.submenu} showMenu={openDropdown} isMobileMode={isMobile} mobileViewClickHandler={mobileViewClickHandler}/>
                  </Dropdown>
                ) : (
                <Link to={item.url} className={`${item.current ? 'text-white' : 'text-white lg:text-sky-100 hover:text-white'} 
                  ${isMobile ? 'p-5' : 'px-3 py-2'} rounded-md  text-md font-medium cursor-pointer`} onClick={mobileViewClickHandler}>
                    {item.name}
                </Link>
                
            )}
        </>
    )
}

export default MenuItems;