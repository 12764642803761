const Contact = () => {
  return (
    <form>
      <div className="mx-auto bg-white p-4 max-w-7xl sm:p-6 lg:p-8">
        <div className="mx-auto pb-12">
          <h2 className="border-b border-gray-900/10 text-2xl font-semibold leading-7 text-amber-500 pb-4">
            Contact Us
          </h2>

          <div className="mx-auto max-w-2xl mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3 relative">
              <input type="text" name="first-name" id="first-name" autoComplete="given-name" placeholder=""
                className="block w-full text-sm text-gray-500 border-0 ring-1 ring-inset ring-gray-300 appearance-none rounded-md 
                p-2 focus:ring-blue-500 focus:outline-0 bg-transparent peer"
              />
              <label htmlFor="first-name" className="absolute text-sm text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 
                origin-[0] bg-white px-1 peer-focus:px-1 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 
                peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-2">
                First name
              </label>
            </div>

            <div className="sm:col-span-3 relative">
              <input
                type="text"
                name="last-name"
                id="last-name"
                autoComplete="family-name"
                placeholder=""
                className="block w-full text-sm text-gray-500 border-0 ring-1 ring-inset ring-gray-300 appearance-none rounded-md 
                p-2 focus:ring-blue-500 focus:outline-0 bg-transparent peer"
              />
              <label htmlFor="last-name" className="absolute text-sm text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-white
                px-1 peer-focus:px-1 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-2">
                Last name
              </label>
            </div>

            <div className="sm:col-span-3 relative">
              <input
                  type="text"
                  name="organization"
                  id="organization"
                  autoComplete="organization"
                  placeholder=""
                  className="block w-full border-0 ring-1 ring-inset ring-gray-300 appearance-none rounded-md p-2 text-gray-500 focus:ring-blue-500 focus:outline-0 placeholder:text-gray-400 text-sm peer"
                  />
              <label htmlFor="organization" className="absolute text-sm text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-white
                px-1 peer-focus:px-1 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-2">
                Organization Name
              </label>
            </div>

            <div className="sm:col-span-3 relative">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                placeholder="you@example.com"
                className="block w-full border-0 ring-1 ring-inset ring-gray-300 appearance-none rounded-md p-2 
                text-gray-500 focus:ring-blue-500 focus:outline-0 text-sm peer placeholder:text-transparent focus:placeholder:text-gray-400"
              />
              <label htmlFor="email" className="absolute text-sm text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-white
                px-1 peer-focus:px-1 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-2">
                Email address
              </label>
            </div>

            <div className="sm:col-span-3 relative">
              <input
                  type="text"
                  name="phonenum"
                  id="phonenum"
                  autoComplete="phonenum"
                  placeholder=""
                  className="block w-full border-0 ring-1 ring-inset ring-gray-300 appearance-none rounded-md p-2 text-gray-500 focus:ring-blue-500 focus:outline-0 placeholder:text-gray-400 text-sm peer"
                  />
              <label htmlFor="phonenum" className="absolute text-sm text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-white
                px-1 peer-focus:px-1 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-2">
                Phone number
              </label>
            </div>

            <div className="sm:col-span-3 relative">
              <input
                  type="text"
                  name="officenum"
                  id="officenum"
                  autoComplete="officenum"
                  placeholder=""
                  className="block w-full border-0 ring-1 ring-inset ring-gray-300 appearance-none rounded-md p-2 text-gray-500 focus:ring-blue-500 focus:outline-0 placeholder:text-gray-400 text-sm peer"
                  />
              <label htmlFor="officenum" className="absolute text-sm text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-white
                px-1 peer-focus:px-1 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-2">
                Office number
              </label>
            </div>

            <div className="sm:col-span-4 relative">
              <input
                  type="text"
                  name="subject"
                  id="subject"
                  autoComplete="subject"
                  placeholder=""
                  className="block w-full border-0 ring-1 ring-inset ring-gray-300 appearance-none rounded-md p-2 text-gray-500 focus:ring-blue-500 focus:outline-0 placeholder:text-gray-400 text-sm peer"
                  />
              <label htmlFor="subject" className="absolute text-sm text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-white
                px-1 peer-focus:px-1 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-2">
                Subject
              </label>
            </div>

            <div className="sm:col-span-6 relative">
              <textarea
                id="about"
                name="about"
                rows={3}
                placeholder=""
                className="block resize-none w-full border-0 ring-1 ring-inset ring-gray-300 appearance-none rounded-md p-2 text-gray-500 focus:ring-blue-500 focus:outline-0 placeholder:text-gray-400 text-sm peer"
                defaultValue={''}
                />
              <label htmlFor="message" className="absolute text-sm text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-white
                px-1 peer-focus:px-1 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-2">
                Message
              </label>
            </div>
            <div className="col-span-full mt-6 flex items-center justify-end">
                <button type="submit" className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                    Send
                </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Contact;
