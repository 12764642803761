import React from 'react';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="bg-gradient-to-r from-violet-500 to-sky-400 mx-auto px-4 py-8 text-center text-white">
           &copy; {currentYear} Farida Virani. All rights reserved.
        </footer>
    )
}

export default Footer;