import React, { useState } from "react";
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid'
import { menuItems } from "../Shared/data/menuItems";
import MenuItems from './MenuItems';


const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <nav className="bg-gradient-to-r from-violet-500 to-sky-400 mx-auto px-4 py-2 sm:px-6 lg:px-8">
      <div className="relative flex h-16 items-center justify-end">
        <div className="flex flex-1 items-center justify-end sm:items-stretch">
          {/* Mobile menu icon*/}
          <div className="absolute inset-y-0 left-0 flex items-center sm:block lg:hidden">
            <button className="relative inline-flex items-center justify-center rounded-md p-2 text-white hover:bg-violet-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              onClick={() => setOpenMenu(prev => !prev)}>
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  { openMenu ? (
                    <XMarkIcon className="block h-8 w-8" aria-hidden="true"/>
                  ) : (
                    <Bars3Icon className="block h-8 w-8" aria-hidden="true" />
                  )}
            </button>
          </div>
          {/* Mobile menu icon end*/}

          {/* Desktop menu */}
          <div className="sm:ml-6 hidden lg:block">
            <div className="flex space-x-4">
              {menuItems.map((item, index) =>
                <MenuItems 
                  key={index} 
                  item={item}
                />
              )}
            </div>
          </div>

        </div>
      </div>
      {openMenu && 
        <div className="flex flex-1 justify-start flex-col w-full h-screen z-10">
          {menuItems.map((item, index) =>
            <MenuItems 
              key={index} 
              item={item}
              mobileViewClickHandler={() => setOpenMenu(false)}
            />
          )}
        </div>
      }
    </nav>
  );
};

export default Header;
