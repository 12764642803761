export const menuItems = [
    { name: "Home", url: "/", current: true },
    { name: "Bio", url: "/bio", current: false },
    { name: "Capabilities", url: "/capabilities", current: false },
    { name: "Research & Publications", url: "#", current: false, 
        submenu: [
        {
            name: 'Articles',
            url: 'articles',
        },
        {
            name: 'Book and Book Chapters',
            url: 'publications',
        },
        {
            name: 'Presentations',
            url: 'presentations',
        },
        {
            name: 'Research Papers',
            url: 'papers',
        }
        ] 
    },
    { name: "Professional Footprints", url: "/footprints", current: false },
    { name: "Testimonials", url: "/testimonials", current: false },
    // { name: "Blog", url: "/blog", current: false },
    { name: "Contact", url: "/contact", current: false }
]